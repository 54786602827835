import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import formatMoney from '../utils/formatMoney';

const ProductGrid = styled.div`
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px; */

  a {
    color: var(--paragraph);
  }
`;

const SingleProduct = styled.div`
  .gatsby-image-wrapper {
    max-width: 100%;
    width: 100%;
    height: 400px;
  }
  transition: 0.2s;
  a {
    color: var(--paragraph);
  }

  a:hover {
    color: var(--paragraph);
  }

  .product-title {
    margin: 15px 0;
    color: var(--heading-color);
  }

  .product-price {
    opacity: 0.5;
    margin-top: 15px;
  }

  &:hover {
    transform: scale(1.04);
    opacity: 0.7;
  }
`;

export default function ProductsGrid({ products }) {
  console.log(products);

  if (!products.length) {
    return (
      <div
        className="container"
        style={{ paddingTop: '60px', paddingBottom: '20rem' }}
      >
        <p>
          There are currently no products available! Please check back soon! :(
        </p>
      </div>
    );
  }

  return (
    <ProductGrid className="container">
      {products.map((product) => {
        const image = getImage(product?.mainImage?.asset);

        return (
          <Link key={product.id} to={`/${product.slug.current}`}>
            <SingleProduct>
              <GatsbyImage image={image} alt={product.title} />
              <h4 className="product-title">{product.title}</h4>
              <p className="product-price">{formatMoney(product.price)}</p>
            </SingleProduct>
          </Link>
        );
      })}
    </ProductGrid>
  );
}

ProductsGrid.propTypes = {
  products: PropTypes.array,
};
