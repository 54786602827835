import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import Breadcrumb from '../components/Layout/Breadcrumb';
import ProductsGrid from '../components/ProductsGrid';
import SEO from '../components/Layout/SEO';

export const query = graphql`
  query ($id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
    }

    products: allSanityProduct(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        title
        price
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(formats: AUTO, width: 400, height: 400)
          }
        }
      }
    }
  }
`;

export default function CategoryPage({ location, data }) {
  return (
    <>
      <SEO
        title={data.category.title}
        location={location.href}
        description={data.category.description || data.category.title}
        image={
          data.category.image.asset.url ? data.category.image.asset.url : ''
        }
      />
      <Breadcrumb>
        <div className="container">
          <Link to="/">Home</Link>/
          <Link to={`/${data.category.slug.current}`}>
            {data.category.title}
          </Link>
        </div>
      </Breadcrumb>

      <section>
        <div className="container">
          <h2>{data.category.title}</h2>
          {data.category.description ? <p>{data.category.description}</p> : ''}
        </div>
      </section>

      <section>
        <ProductsGrid products={data.products?.nodes} />
      </section>
    </>
  );
}

CategoryPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

// To Do

// may need to keep a log of the current variable to pass into products to keep the breadcrumb going

// create a in category description on the page

// create a grid to display the products image, title and price
