import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BreadcrumbStyle = styled.nav`
  width: 100vw;

  a {
    padding: 14px 16px;
    display: inline-block;

    &:first-child {
      padding-left: 0;
    }

    &[aria-current='page'] {
      color: var(--headline);
      text-decoration: underline;
    }
  }
`;

export default function Breadcrumb({ children }) {
  return <BreadcrumbStyle>{children}</BreadcrumbStyle>;
}

Breadcrumb.propTypes = {
  children: PropTypes.element,
};
